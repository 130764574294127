import { lazyRemoveAttachmentPreviews } from 'owa-mail-attachment-previews';
import type TableView from 'owa-mail-list-store/lib/store/schema/TableView';
import getListViewStore from 'owa-mail-list-store/lib/store/Store';
import getTableToRowRelationKey from 'owa-mail-list-store/lib/utils/getTableToRowRelationKey';
import { getConversationCache } from 'owa-mail-store/lib/store/conversationCache';
import { mutatorAction } from 'satcheljs';
import { lazyRemoveMeetingMessagesFromStore } from 'owa-listview-rsvp';

export default function removeConversationData(rowKey: string, tableView: TableView) {
    const tableConversationRelationKey = getTableToRowRelationKey(rowKey, tableView.id);
    const tableConversationRelations = getListViewStore().tableViewConversationRelations;
    const conversationId = tableConversationRelations.get(tableConversationRelationKey)?.id;
    const itemIdsForConversation = tableConversationRelations.get(
        tableConversationRelationKey
    )?.itemIds;
    if (!tableConversationRelations.has(tableConversationRelationKey)) {
        throw new Error('Conversation not found in store');
    }
    // 1. Remove table Conversation relation
    removeTableConversationRelationMutator(tableConversationRelationKey);

    // 2. Check to see if there are any other table relations for this converation
    let converationFoundInOtherTables = false;
    for (const table of getListViewStore().tableViews.values()) {
        // We should not refer to the current table
        if (
            table.id != tableView.id &&
            conversationId &&
            table.rowIdToRowKeyMap.get(conversationId)
        ) {
            converationFoundInOtherTables = true;
            break;
        }
    }
    // 3. Only remove conversation from listview store when it is not referenced anymore from any table
    if (!converationFoundInOtherTables && conversationId) {
        removeConversationMutator(conversationId);

        // Remove attachment preview well and atttachment view states for this conversation
        lazyRemoveAttachmentPreviews.importAndExecute(conversationId);
        if (itemIdsForConversation) {
            lazyRemoveMeetingMessagesFromStore.importAndExecute(itemIdsForConversation);
        }
    }
}

const removeTableConversationRelationMutator = mutatorAction(
    'removeTableConversationRelation',
    (key: string) => {
        getListViewStore().tableViewConversationRelations.delete(key);
    }
);

const removeConversationMutator = mutatorAction('removeConversation', (conversationId: string) => {
    getListViewStore().conversationItems.delete(conversationId);

    // Remove cached ConversationItemParts from conversationCache if exists
    getConversationCache().remove(conversationId);
});
