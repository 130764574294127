import { LazyAction, LazyModule, LazyImport } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "TabViewStore" */ './lazyIndex'),
    { name: 'TabViewStore' }
);

export const lazyActivateTab = new LazyAction(lazyModule, m => m.activateTab);
export const lazyAddTab = new LazyAction(lazyModule, m => m.addTab);
export const lazyCloseTab = new LazyAction(lazyModule, m => m.closeTab);
export const lazySetTabIsShown = new LazyAction(lazyModule, m => m.setTabIsShownMutator);
export const lazyReloadSecondaryTabAsDeeplink = new LazyAction(
    lazyModule,
    m => m.reloadSecondaryTabAsDeeplink
);
export const lazyRemoveTabViewState = new LazyAction(lazyModule, m => m.removeTabViewState);

export const lazyGetTabById = new LazyImport(lazyModule, m => m.getTabById);

export type { default as SecondaryReadingPaneTabData } from './store/schema/SecondaryReadingPaneTabData';
export { getStore, primaryTab } from './store/tabStore';
export { default as addFullOptionsTab } from './utils/addFullOptionsTab';
export { default as getActiveContentTab } from './utils/getActiveContentTab';
export { default as findTabByData } from './utils/findTabByData';
export { default as findTabIdBySxSId } from './utils/findTabIdBySxSId';
export {
    default as secondaryTabsHaveId,
    otherSecondaryTabsRepresentSameItem,
} from './utils/secondaryTabsHaveId';
export { default as getTabTitle } from './utils/getTabTitle';
export { TabType, TabState } from './store/schema/TabViewState';
export type {
    default as TabViewState,
    SecondaryReadingPaneTabViewState,
    MailComposeTabViewState,
    CalendarComposeTabViewState,
    CalendarReadingPaneTabViewState,
    FullOptionTabViewState,
    RemindersTabViewState,
    AddCalendarsTabViewState,
    FileErrorTabViewState,
} from './store/schema/TabViewState';
export { registerTabHandler } from './utils/TabHandler';
export type { default as TabHandler } from './utils/TabHandler';
export { default as getPopoutTabsViewState } from './selectors/getPopoutTabsViewState';
export { default as getTabsByTabType, getFullOptionsTab } from './selectors/getTabsByTabType';
