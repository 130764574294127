import shouldSkipProcessRow from './helpers/shouldSkipProcessRow';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline. Do not copy and paste"
 *	> '../index' import is restricted from being used. */
import { lazyFetchRichContentForRows } from '../index';
import type { MailListRowDataType, TableView } from 'owa-mail-list-store';
import { getRowKeyFromListViewType } from 'owa-mail-list-store';
import updateInstrumentationContext from 'owa-mail-list-store/lib/utils/updateInstrumentationContext';
import TableOperations from 'owa-mail-list-table-operations';
import type { InstrumentationContext } from 'owa-search-types/lib/types/InstrumentationContext';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * satcheljs/lib/legacy imports are not allowed
 *	> 'satcheljs/lib/legacy' import is restricted from being used. */
import { action } from 'satcheljs/lib/legacy';

export default action('appendRowResponse')(
    /**
     * Process FindConversation/FindItem response and append them to the end of the given TableView
     * @param tableView to append results in
     * @param rows to be appended
     * @param totalRowsInViewInResponse - total rows in the table returned from server
     * @param source - source of append
     * @param searchResponseId - the search query id
     * @param doNotOverwriteData determines if updates should be written (default is false)
     */
    function appendRowResponse(
        tableView: TableView,
        rows: MailListRowDataType[],
        totalRowsInViewInResponse: number,
        source: string = '',
        searchResponseId?: string,
        searchLogicalId?: string,
        doNotOverwriteData: boolean = false
    ) {
        const rowList: MailListRowDataType[] = rows || [];
        let failedCount = 0;

        for (const row of rowList) {
            // Check if row should be processed
            if (shouldSkipProcessRow(row, tableView)) {
                continue;
            }

            let wasSuccessful = true;
            const rowKey = row.InstanceKey;

            // Append all rows to end of table
            if (
                TableOperations.containsRowKey(
                    tableView,
                    getRowKeyFromListViewType(row, tableView.tableQuery.listViewType)
                )
            ) {
                const insertIndex = tableView.rowKeys.length - 1;

                // Move row to new position in table if it already exists. There could be some timing issue
                // where the row was moved down on the server. In this case we want to keep the server's latest order
                TableOperations.updateRowMutator(
                    insertIndex,
                    row,
                    tableView,
                    source,
                    doNotOverwriteData
                );
            } else {
                wasSuccessful = TableOperations.appendRowMutator(
                    row,
                    tableView,
                    source,
                    doNotOverwriteData
                );
            }

            // Account for failures
            if (!wasSuccessful) {
                failedCount++;
            }

            if (searchResponseId) {
                const instrumentationContext = <InstrumentationContext>{
                    referenceKey: row.ReferenceKey,
                    index: tableView.rowKeys.length - 1,
                    traceId: searchResponseId,
                    logicalId: searchLogicalId,
                };

                // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
                // -> Error TS2345 (99,46): Argument of type 'Maybe<string> | undefined' is not assignable to parameter of type 'string'.
                // @ts-expect-error
                updateInstrumentationContext(rowKey, tableView, instrumentationContext);
            }
        }

        // VSO 1285: optimize this number on empty folder
        tableView.totalRowsInView = totalRowsInViewInResponse - failedCount;

        // FetchRichContentForRows
        lazyFetchRichContentForRows.importAndExecute(tableView);
    }
);
