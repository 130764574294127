import { mutatorAction } from 'satcheljs';
import type TabViewState from '../store/schema/TabViewState';
import type { TabState } from '../store/schema/TabViewState';

export const setTabStateMutator = mutatorAction(
    'setTabState',
    (viewState: TabViewState, state: TabState, blink?: boolean) => {
        viewState.state = state;
        if (typeof blink === 'boolean') {
            viewState.blink = blink;
        }
    }
);
