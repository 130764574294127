import is3SServiceAvailable from 'owa-search/lib/utils/is3SServiceAvailable';
import getSearchProvider from 'owa-search/lib/selectors/getSearchProvider';
import isBusiness from 'owa-session-store/lib/utils/isBusiness';
import { SearchProvider } from 'owa-search-service/lib/data/schema/SearchProvider';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import store from '../store/store';
import SearchScenarioId from 'owa-search-store/lib/store/schema/SearchScenarioId';

export default function isAnswerFeatureEnabled() {
    return isCommonAnswerEnabled() || isBusinessAnswersEnabled();
}

function isCommonAnswerEnabled(): boolean {
    return is3SServiceAvailable();
}

function isBusinessAnswersEnabled(): boolean {
    return is3SServiceAvailable() && isBusiness(getModuleContextMailboxInfo());
}

export function isSingleCallForAnswersAndSerpEnabled(): boolean {
    return (
        isAnswerFeatureEnabled() &&
        getSearchProvider(store.staticSearchScope, SearchScenarioId.Mail) ===
            SearchProvider.SubstrateV2
    );
}
