import type TabViewState from '../store/schema/TabViewState';
import { type TabState } from '../store/schema/TabViewState';
import { activateTab } from './activateTab';
import { setTabStateMutator } from './setTabStateMutator';
import { getStore } from '../store/tabStore';

function getNextVisibleTab(tab: TabViewState): TabViewState | null {
    const tabs = getStore().tabs;
    const startIndex = Math.max(tabs.indexOf(tab), 0);
    const nextActiveTab = tabs.filter(t => t.id == tab.parentTabId && t.state == 1)[0];

    if (nextActiveTab) {
        return nextActiveTab;
    }

    for (let i = startIndex; i < tabs.length; i++) {
        if (tabs[i].state == 1) {
            return tabs[i];
        }
    }

    for (let i = startIndex - 1; i >= 0; i--) {
        if (tabs[i].state == 1) {
            return tabs[i];
        }
    }

    return null;
}

export function setTabIsShownMutator(viewState: TabViewState, isShown: boolean) {
    switch (viewState.state) {
        case 0:
            if (isShown) {
                setTabStateMutator(viewState, 1, true /* blink */);
            }
            break;

        case 1:
        case 2:
        case 3:
            if (!isShown) {
                const nextActiveTab = viewState.state == 2 && getNextVisibleTab(viewState);

                if (nextActiveTab) {
                    activateTab(nextActiveTab);
                }

                setTabStateMutator(viewState, 0);
            }
            break;
    }
}
