import type TabViewState from '../store/schema/TabViewState';
import { TabType, type TabState } from '../store/schema/TabViewState';
import getActiveContentTab from '../utils/getActiveContentTab';
import { setTabIsShownMutator } from './setTabIsShown';
import { setTabStateMutator } from './setTabStateMutator';
import { PerformanceDatapoint } from 'owa-analytics';
import { getTabHandler } from '../utils/TabHandler';
import { isFeatureEnabled } from 'owa-feature-flags';
import { errorThatWillCauseAlertAndThrow } from 'owa-trace';

export function activateTab(viewState: TabViewState, isUserAction?: boolean) {
    const dp = new PerformanceDatapoint('TabViewActivateTab', { isVerbose: true });
    dp.addCustomData([viewState.type, isUserAction]);
    setTabIsShownMutator(viewState, true /*isShown*/);

    switch (viewState.type) {
        case TabType.Primary:
        case TabType.OverflowMenu:
        case TabType.SecondaryReadingPane:
        case TabType.MailCompose:
        case TabType.FullOptions:
        case TabType.Reminders:
        case TabType.AddCalendars:
            if (
                viewState.type === TabType.FullOptions &&
                !isFeatureEnabled('acct-fullOptionsProjectionPopout')
            ) {
                errorThatWillCauseAlertAndThrow(
                    'activateTab should NOT be called for FullOptions when acct-fullOptionsProjectionPopout flight is OFF'
                );
            }

            if (viewState.state != 2) {
                const activeTab = getActiveContentTab();

                if (activeTab) {
                    setTabStateMutator(activeTab, 1);
                    const handler = getTabHandler(activeTab.type);
                    if (handler?.onDeactivate) {
                        handler.onDeactivate(activeTab);
                    }
                }

                if (viewState) {
                    setTabStateMutator(viewState, 2, /* blink */ false);

                    const handler = getTabHandler(viewState.type);
                    if (handler?.onActivate) {
                        handler.onActivate(viewState);
                    }
                }
            }
    }
    dp.end();
}
